












































































































































import mixins from 'vue-typed-mixins'
import FlexibleBookingMonthlyCalendar from '@/builder/sections/section/shared/mixins/FlexibleBookingMonthlyCalendar'
import EmployeePicker from '@/builder/sections/section/shared/components/EmployeePicker.vue'
import BookingTimePicker from '@/builder/sections/section/shared/components/BookingTimePicker.vue'
import TimeZoneInput from '@/builder/sections/shared/TimeZoneInput.vue'
import LocationInput from '@/builder/sections/shared/LocationInput.vue'

export default mixins(FlexibleBookingMonthlyCalendar).extend({
  name: 'FlexibleBooking2',
  components: {
    LocationInput,
    TimeZoneInput,
    BookingTimePicker,
    EmployeePicker
  }
})
