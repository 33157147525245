<template>
  <v-card
    :dark="dark"
    :color="color"
    :outlined="outlined"
    :flat="flat"
    class="hour-input"
    :class="{
      'hour-input-no-actions': loading,
      [roundClass]: true
    }"
  >
    <div
      v-if="loading"
      class="hour-input__loading"
    >
      <v-progress-circular
        indeterminate
        color="wb_color_primary__color__"
      />
    </div>
    <v-list v-if="hours && hours.length > 0 && !loading" :dark="dark" :flat="flat" :color="color">
      <v-list-item-group
        :color="color"
        v-model="active"
        :dark="dark"
      >
        <v-sheet
          :dark="dark"
          :class="{
          [roundClass]: true,
          [color]: true
        }"
          outlined
          class="ma-2 hour-wrapper"
          v-for="(item, i) in hours"
          :key="i">
          <v-list-item
            :dark="dark"
            :value="item"
            class="text-center"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item" />
            </v-list-item-content>
            <v-list-item-action v-if="bookingButton && item === activeHour">
              <v-btn
                color="wb_color_primary__color__"
                depressed
                @click="$emit('book', activeHour)"
              >
                {{ bookNowLabel }}
              </v-btn>
            </v-list-item-action>
            <v-list-item-icon v-if="!bookingButton && item === activeHour">
              <v-icon v-text="'$check'" />
            </v-list-item-icon>
          </v-list-item>
        </v-sheet>
      </v-list-item-group>
    </v-list>
    <div
      v-else-if="!loading"
      class="hour-input__no-data"
    >
      <v-container>
        <v-row>
          <v-col class="text-center font-weight-medium">
            <span>{{ $trans('no_free_hours') }}</span>
          </v-col>
        </v-row>
        <v-row v-if="firstAvailableDate">
          <v-col class="text-center">
            {{ $trans('first_available_date')}}:<br><span class="font-weight-medium">{{ firstAvailableDate | moment('dddd D MMMM') }}</span>
          </v-col>
        </v-row>
        <v-row v-if="firstAvailableDate">
          <v-col class="d-flex justify-center">
            <v-btn
              color="wb_color_primary__color__"
              depressed
              @click="$emit('date', firstAvailableDate)"
            >
              {{ $trans('select_date') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BookingTimePicker',
  model: {
    prop: 'activeHour',
    event: 'selectedHour'
  },
  props: {
    hours: {
      type: Array,
      required: true
    },
    activeHour: {
      type: String
    },
    color: {
      type: String
    },
    firstAvailableDate: {
      type: String
    },
    roundClass: {
      type: String,
      default: 'rounded'
    },
    loading: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    bookNowLabel: {
      type: String,
      default: null
    },
    bookingButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: null
    }
  },
  watch: {
    active () {
      this.$emit('selectedHour', this.active)
    },
    activeHour () {
      this.active = this.activeHour
    },
    hours () {
      this.active = null
    }
  },
  mounted () {
    this.active = this.activeHour
  }
})
</script>

<style lang="scss" scoped>
.hour-input {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.hour-input-no-actions {
  pointer-events: none;
}

.hour-input__loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-list-item {
  height: 56px;
}

.hour-wrapper {
  border-color: var(--v-wb_color_primary__color__-base);
}

.hour-input__no-data {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
